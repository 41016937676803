<template>
	<div>
		<div class="header filter">
			<!-- 해더 -->
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<div class="page_tit_area">
					<h2 class="page_tit">{{  program.title }}</h2>
				</div>
			</div>
			<!-- //해더 -->
			<!-- 친구검색 -->
			<div class="serch_top">
				<div class="top top_wh">
					<div class="header_search">
						<input
							v-model="item_search.search_value"
							type="text" placeholder="멤버 검색"
							maxlength="20"
						>
					</div>
					<div class="header_search_btn">
						<div class="btn_del">
							<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
							<button
								v-if="item_search.search_value"
								class="active"
								@click="item_search.search_value = ''; getData()"
							><span class="hide">닫기</span></button>
						</div>
						<div class="util">
							<button
								@click="getData"
								class="img_button" type="submit" name="click"
							><span class="hide">검색</span></button>
						</div>
					</div>
				</div>
			</div>
			<!-- //친구검색 -->
		</div>
		<!-- //고정해더 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-120">
			<!-- 멤버초대 -->
			<div class="thumbnail_wrap">
				<div class="container">
					<template
						v-if="items_member.length > 0"
					>
					<div class="row">
						<ul class="thumbnail_list ct_invite">
							<h3 class="hide">친구 검색 결과</h3>
							<li
								v-for="(item, index) in invite_member"
								:key="'item_' + index"
							>
								<div class="ct_invite_list">
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item.profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url)"
												>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
												/>
											</div>
											<div class="text_area">
												<strong>{{ item.nickname }}</strong>
												<p class="textzone textzone">
													{{  item.state_message }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn">
											<button
												@click="onConfirm(item)"
												class="btn_outline_blue btn_s"
												:disabled="item.is_invite"
											>차단해제</button>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
					</template>
					<div
						v-else
					>
						<div class="list_none" style="overflow: hidden; margin-top: 50%">
							<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
							<span>차단된 멤버가 없습니다.</span>
						</div>
					</div>
				</div>
			</div>
			<!-- //멤버초대 -->
		</div>

		<PopupConfirm
			v-if="is_confirm"

			@click="postBlockCancel"
			@cancel="is_confirm = false"
		>
			<template
				v-slot:title
			>차단 해제</template>
			<template
				v-slot:main-txt
			>차단 해제 하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>차단 해제 하시려면 확인을 눌러주세요.</template>
		</PopupConfirm>
	</div>
</template>

<script>
import PopupConfirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'mafia112'
	, components: {PopupConfirm}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '차단 멤버 '
				, title: '차단 멤버'
				, not_header: true
				, not_footer: true
				, type: 'mypage'
			}
			, items_member: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				seach_type: ''
				, search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, is_confirm: false
			, item_block: {}
		}
	}
	, computed: {
		invite_member: function(){
			
			this.$log.console(this.item_cartel.user)
			return this.items_member.filter((item_member) => {

				item_member.is_invite = false
				if(this.item_cartel.user){
					if(this.item_cartel.user.indexOf(item_member.id) > -1){
						item_member.is_invite = true
					}
				}
				return item_member
			})
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_black_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.blok_member_list
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, postInvite: async function(member_id){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_invite
					, data: {
						cartel_id: this.item_cartel.id
						, member_id: member_id
					}
					, name: 'postInvite'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: '초대요청이 완료되었습니다'})
					await this.getCartel()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postAlarm: async function(friend){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_friend_alarm
					, data: {
						user_id: this.user.id
						, friend_id: friend.friend_id
					}
					, name: 'postAlarm'
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postBlockCancel: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_black_cancel
					, data: {
						member_number: this.user.member_number
						, blok_member_number: this.item_block.blok_member_number
					}
					, type: true
				})

				this.$log.console('postFriend result ', result)
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, onConfirm: function(item_block){
			this.item_block = item_block
			this.is_confirm = true
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getData()
	}

}
</script>

<style>
</style>